import React, { useEffect, useState } from "react";
import { Table, Form, InputGroup, Button, Modal, Badge} from "react-bootstrap";
import CampagnesDataService from "../../../../context/compagnes.services";



const CampagnesList = ({id, getCampagneId, setCampagneId }) => {

  const [modifierAffiche, setModifierAffiche] = useState(false);
  const [campagnes, setCampagne] = useState([]);
  const [nom, setNom] = useState("");
  const [description, setDescription] = useState("");
  const [urlImage, setUrlImage] = useState("");
  const [men, setMensuel] = useState(false);
  const [ordre, setOrdre] = useState(0);
  const [message, setMessage] = useState({ error: false, msg: "" });

  const ModifierCampagneHandler = (nom) => {
    getCampagneId(nom)
    setModifierAffiche(true)
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (nom === "" || description === "" || urlImage === "" || ordre === 0) {
      setMessage({ error: true, msg: "Tout les champs sont obligatoire" });
      return;
    }
    const newCampagne = {
      nom,
      description,
      urlImage,
      men,
      ordre: parseInt(ordre)
    };
    try {
      if (id !== undefined && id !== "") {
        await CampagnesDataService.updateCampagne(id, newCampagne);
        setCampagneId("");
        setMessage({ error: false, msg: "Mis à jour avec succés !" });
        getCampagnes();
        setModifierAffiche(false);
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
    setNom("");
    setDescription("");
    setUrlImage("");
    setMensuel(false);
    setOrdre(0);
  };
  const editHandler = async () => {
    setMessage("");
    try {
      const docSnap = await CampagnesDataService.getCampagne(id);
      setNom(docSnap.data().nom);
      setDescription(docSnap.data().description);
      setUrlImage(docSnap.data().urlImage);
      setMensuel(docSnap.data().men);
      setOrdre(docSnap.data().ordre);
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  useEffect(() => {
    if (id !== undefined && id !== "") {
      editHandler();
    }
  }, [id]);
  useEffect(() => {
    getCampagnes();
  }, []);

  const getCampagnes = async () => {
    const data = await CampagnesDataService.getAllCampagnes();
    setCampagne(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const deleteHandler = async (id) => {
    await CampagnesDataService.deleteCampagne(id);
    getCampagnes();
  };
  return (
    <>
      <Modal show={modifierAffiche} fullscreen={true} onHide={() => setModifierAffiche(false)}>
        <Modal.Header closeButton>
          <Modal.Title><span className="text-success">Campagne :</span> {nom}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBookTitle">
            <InputGroup>
              <InputGroup.Text id="formBookTitle">Nom</InputGroup.Text>
              <Badge bg="secondary" className="p-3 mx-1">{nom}</Badge>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBookTitle">
            <InputGroup>
              <InputGroup.Text id="formBookTitle">Description</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="ex : Description..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBookTitle">
            <InputGroup>
              <InputGroup.Text id="formBookTitle">Url Image</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="ex : https://...."
                value={urlImage}
                onChange={(e) => setUrlImage(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBookTitle">
            <InputGroup>
              <InputGroup.Text id="formBookTitle">Mensuel</InputGroup.Text>
              <div className="d-grid gap-2 d-md-block ">
                <button className={men ? "btn btn-primary mx-3" : "btn btn-outline-primary mx-3"} type="button" onClick={() => setMensuel(true)}>Oui</button>
                <button class={men ? "btn btn-outline-primary" : "btn btn-primary"} type="button" onClick={() => setMensuel(false)}>Non</button>
</div>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBookAuthor">
            <InputGroup>
              <InputGroup.Text id="formBookAuthor">Ordre</InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="..."
                value={ordre}
                onChange={(e) => setOrdre(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
          <div className="d-grid gap-2">
            <Button variant="info text-light" type="Submit">
              Confirmer
            </Button>
            <Button variant="secondary text-light" onClick={() => setModifierAffiche(false)}>
              Annuler
            </Button>
          </div>
        </Form>
        </Modal.Body>
      </Modal>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Description</th>
            <th>Image</th>
            <th>Placement</th>
            <th>Mensuel</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {campagnes.map((doc, index) => {
            return (
              <tr key={index}>
                <td>{doc.nom}</td>
                <td>{doc.description}</td>
                <td><img src={doc.urlImage} height='40px'/></td>
                <td>{doc.ordre}</td>
                <td>{doc.men ? 'Activer' : 'Désactiver'}</td>
                <td>
                  <Button
                    variant="secondary"
                    className="edit"
                    onClick={(e) => ModifierCampagneHandler(doc.nom)}
                  >
                    Modifier
                  </Button>
                  <Button
                    variant="danger"
                    className="delete"
                    onClick={(e) => deleteHandler(doc.nom)}
                  >
                    Supprimer
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default CampagnesList;