import React from 'react';
import '../style.css';
import { Container, Row, Col } from "react-bootstrap";
import DonateursList from './DonateursList';

export default function Donateur() {
    return (
        <div className="global-container">
            <h1>Donateurs</h1>
      <Container>
        <Row>
          <Col>
            <DonateursList />
          </Col>
        </Row>
      </Container>
        </div>
    );
}