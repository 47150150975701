import { collection, getDocs, getDoc, query, orderBy, updateDoc, deleteDoc, doc, setDoc } from "firebase/firestore";
import { db } from '../firebase-config';

class CampagnesDataService {
  updateCampagne = (id, updatedCampagne) => {
    const campagneDoc = doc(db, "compagnes", id);
    return updateDoc(campagneDoc, updatedCampagne);
  };
  addCampagne = (newCampagne) => {
    return setDoc(doc(db, "compagnes", newCampagne.nom), newCampagne);
  };
  deleteCampagne = (id) => {
    const compagnesDoc = doc(db, "compagnes", id);
    return deleteDoc(compagnesDoc);
  };
  getAllCampagnes = () => {
    const q = query(collection(db, "compagnes"), orderBy("ordre", "desc"));
    return getDocs(q);
  };
  getCampagne = (id) => {
    const campagneDoc = doc(db, "compagnes", id);
    return getDoc(campagneDoc);
  };
}

export default new CampagnesDataService();