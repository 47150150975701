import React from 'react';
import '../style.css';
import { Container, Row, Col } from "react-bootstrap";
import DonsList from './DonsList';

export default function Don() {
    return (
        <div className="global-container">
            <h1>Dons</h1>
      <Container>
        <Row>
          <Col>
            <DonsList />
          </Col>
        </Row>
      </Container>
        </div>
    );
}