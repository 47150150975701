import React, { useContext, useRef, useState } from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";


function Connexion() {

    const { signIn } = useContext(UserContext);
    const navigate = useNavigate();
    const [validation, setValidation] = useState("");
    const inputs = useRef([]);
    const addInputs = (el) => {
      if (el && !inputs.current.includes(el)) {
        inputs.current.push(el);
      }
    };
    const handleForm = async (e) => {
      e.preventDefault();
      try {
        await signIn(
          inputs.current[0].value,
          inputs.current[1].value
        );
        setValidation("");
        navigate("/private/private-home");
      } catch {
        setValidation("Le mail / mot de passe incorrect")
      }
    };
    
    return (
    <>
        <Container>
            <h1 className="shadow-sm text-success mt-5 p-3 text-center rounded">AssoHayat - Admin</h1>
            <Row className="mt-5">
                <Col lg={5} md={6} sm={12} className="p-5 m-auto shadow-sm rounded-lg">
                    <form onSubmit={handleForm}>
                        <Form.Group role="form">
                            <Form.Label>Email </Form.Label>
                            <Form.Control type="email" placeholder="...@email" required ref={addInputs}/>

                            <Form.Label>Mot de passe</Form.Label>
                            <Form.Control type="password" placeholder="******" required ref={addInputs}/>

                        <p className="text-danger mt-1">{validation}</p>

                        <div className="d-grid gap-2 mt-5">
                          <Button variant="success btn-block" type="submit">
                              Se connecter
                          </Button>
                        </div>
                        </Form.Group>
                    </form>
                </Col>
            </Row>
            <h6 className="mt-5 p-5 text-center text-secondary ">Copyright © 2022 AssoHayat. All Rights Reserved.</h6>
        </Container>
    </>
);
}

export default Connexion;
