import React from 'react';
import './navbar.css';
import logo from '../../images/logo192.webp';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase-config';

export default function Navbar() {
    const navigate = useNavigate()
    const logOut = async () => {
        try{
            await signOut(auth)
            navigate("/")
        } catch {
            alert("Un problème est survenu Veuillez verifier votre connexion internet et recommencer.")
        }
    }
    return (
        <nav className='sidenav'>
            <img src={logo} alt="logo de l'entreprise" width='50px'/>
            <Link to='/private/private-home'>Campagnes</Link>
            <Link to='/private/donateurs'>Donateurs</Link>
            <Link to='/private/dons'>Dons</Link>
            <button onClick={logOut} className='btn btn-danger ms-2'>Se déconnecter</button>
        </nav>
    );
}