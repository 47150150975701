import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import DonnateursDataService from '../../../../context/donnateurs.services';
import { CSVLink } from "react-csv";

const DonateursList = () => {

  const headers = [
    {label: 'Nom', key: 'nom'},
    {label: 'Prénom', key: 'prenom'},
    {label: 'Tél', key: 'tel'},
    {label: 'Email', key: 'email'},
    {label: 'Type', key: 'typa'},
  ]

  const [donateur, setDonateur] = useState([]);
  useEffect(() => {
    getDonateurs();
  }, []);

  const csvReport ={
    filename: `Donateurs ${new Date().getTime()}.csv`,
    headers: headers,
    data: donateur
  }

  const getDonateurs = async () => {
    const data = await DonnateursDataService.getAllDonateurs();
    setDonateur(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const formatDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',  hour: '2-digit', minute: '2-digit' }
    return new Date(dateString).toLocaleDateString('fr-FR', options)
  }

  return (
    <>
      <div className="d-grid gap-2 d-md-block">
        <button className="btn btn-outline-primary m-3" type="button" onClick={getDonateurs}>rafraîchir la liste</button>
        <CSVLink {...csvReport} enclosingCharacter={`'`} className="btn btn-outline-success">Exporter vers csv</CSVLink>
      </div>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Date d'inscription</th>
            <th>Nom / Prénom</th>
            <th>Email</th>
            <th>Tél</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {donateur.map((doc, index) => {
            return (
              <tr key={index}>
                <td>{formatDate(doc.date_inscription.toDate().toISOString())}</td>
                <td>{doc.nom} {doc.prenom}</td>
                <td>{doc.email}</td>
                <td>{doc.tel}</td>
                <td>{doc.typa}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default DonateursList;