import React, { useState } from "react";
import { Form, Alert, InputGroup, Button} from "react-bootstrap";
import CampagnesDataService from "../../../../context/compagnes.services";

const AddCampagne = ({ id }) => {

  const [nom, setNom] = useState("");
  const [description, setDescription] = useState("");
  const [urlImage, setUrlImage] = useState("");
  const [men, setMensuel] = useState(true);
  const [ordre, setOrdre] = useState(0);
  const [message, setMessage] = useState({ error: false, msg: "" });
  const [ajouterAffiche, setAjouterAffiche] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if (nom === "" || description === "" || urlImage === "" || ordre === 0) {
      setMessage({ error: true, msg: "Tout les champs sont obligatoire" });
      return;
    }
    const newCampagne = {
      nom,
      description,
      urlImage,
      men,
      ordre: parseInt(ordre)
    };
    try {
      if (id !== undefined && id !== "") {
      } else {
        await CampagnesDataService.addCampagne(newCampagne);
        setMessage({ error: false, msg: "Nouveau code promo ajouté avec succès !" });
        setAjouterAffiche(false);
        window.location.reload();
        
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
    setNom("");
    setDescription("");
    setUrlImage("");
    setMensuel(false);
    setOrdre(0);
  };
  return (
    <>
      <div className="p-4 box">
        {message?.msg && (
          <Alert
            variant={message?.error ? "danger" : "success"}
            dismissible
            onClose={() => setMessage("")}
          >
            {message?.msg}
          </Alert>
        )}
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <button class="btn btn-info me-md-2 mb-2 text-light" type="button" onClick={() => setAjouterAffiche(true)}>+ Ajouter une campagne</button>
        </div>
        {ajouterAffiche ?         <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBookTitle">
            <InputGroup>
              <InputGroup.Text id="formBookTitle" className="bg-success">Nom</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="ex : NomDeCampagne"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBookTitle">
            <InputGroup>
              <InputGroup.Text id="formBookTitle" className="bg-success">Description</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="ex : Description..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBookTitle">
            <InputGroup>
              <InputGroup.Text id="formBookTitle" className="bg-success">Url Image</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="ex : https://...."
                value={urlImage}
                onChange={(e) => setUrlImage(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBookTitle">
            <InputGroup>
              <InputGroup.Text id="formBookTitle" className="bg-success">Mensuel</InputGroup.Text>
              <div className="d-grid gap-2 d-md-block ">
                <button className={men ? "btn btn-primary mx-3" : "btn btn-outline-primary mx-3"} type="button" onClick={() => setMensuel(true)}>Oui</button>
                <button className={men ? "btn btn-outline-primary" : "btn btn-primary"} type="button" onClick={() => setMensuel(false)}>Non</button>
              </div>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBookAuthor">
            <InputGroup>
              <InputGroup.Text id="formBookAuthor" className="bg-success">Ordre</InputGroup.Text>
              <Form.Control
                type="number"
                placeholder="..."
                value={ordre}
                onChange={(e) => setOrdre(e.target.value)}
              />
            </InputGroup>
          </Form.Group>
          <div className="d-grid gap-2">
            <Button variant="info text-light" type="Submit">
              Confirmer
            </Button>
            <Button variant="secondary text-light" onClick={() => setAjouterAffiche(false)}>
              Annuler
            </Button>
          </div>
        </Form> : ''}
      </div>
    </>
  );
};

export default AddCampagne;