import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AddCampagne from "./AddCampagne";
import CampagnesList from "./CampagnesList";
import "./campagnes.css";

export default function Campagne() {
  const [campagneId, setCampagneId] = useState("");

  const getCampagneIdHandler = (id) => {
    setCampagneId(id);
  };
  return (
    <div className="global-container">
      <h1>Campagnes</h1>
      <Container>
        <Row>
            <AddCampagne id={campagneId} />
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <CampagnesList getCampagneId={getCampagneIdHandler} id={campagneId} setCampagneId={setCampagneId}/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}