import {collection, getDocs, query, orderBy} from "firebase/firestore";
import { db } from '../firebase-config';

class DonnateursDataService {
  getAllDonateurs = () => {
    const q = query(collection(db, "donateurs"), orderBy("date_inscription", "desc"));
    return getDocs(q);
  };
}

export default new DonnateursDataService();