import React,{useContext} from "react";
import {Routes, Route} from "react-router-dom";
import Navbar from "./components/navbar/navbar";
import Page404 from "./components/pageErreur/erreur404";
import { UserContext } from "./context/userContext";
import Connexion from "./pages/connexion/connexion";
import Private from "./pages/private/private";
import Campagne from "./pages/private/privateHome/compagnes/Campagne";
import Donateur from "./pages/private/privateHome/donnateurs/Donateur";
import Don from "./pages/private/privateHome/dons/Don";


function App() {
  const {currentUser} = useContext(UserContext)
  return (
    <>
    {currentUser && <Navbar />}
      <Routes>
        <Route path="/" element={currentUser ? <Campagne /> : <Connexion />}/>
        <Route path="/private" element={<Private/>}>
          <Route path="/private/private-home" element={<Campagne />}/>
          <Route path="/private/donateurs" element={<Donateur/>}/>
          <Route path="/private/dons" element={<Don />}/>
        </Route>
        <Route path='*' element={<Page404 />} />
      </Routes>
    </>
  );
}

export default App;
