import {collection, getDocs, query, orderBy} from "firebase/firestore";
import { db } from '../firebase-config';

class DonsDataService {
  getAllDons = () => {
    const q = query(collection(db, "orders"), orderBy("date_order", "desc"));
    return getDocs(q);
  };
}

export default new DonsDataService();