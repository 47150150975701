import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DonsDataService from '../../../../context/dons.services';

const DonsList = () => {

  const headers = [
    {label: 'Donateur', key: 'donateur'},
    {label: 'Email donateur', key: 'emailDinateur'},
    {label: 'Tel donateur', key: 'telDonateur'},
    {label: 'Type', key: 'type'},
    {label: 'Date de don', key: 'date_order'},
    {label: 'Nom campagne', key: 'id_campagne'},
    {label: 'Montant (€)', key: 'montant'},
    {label: 'Date de prélèvement', key: 'datePrel'}
  ]

  const [don, setDon] = useState([]);
  useEffect(() => {
    getDons();
  }, []);

  const csvReport ={
    filename: `Dons ${new Date().getTime()}.csv`,
    headers: headers,
    data: don
  }

  const getDons = async () => {
    const data = await DonsDataService.getAllDons();
    setDon(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const formatDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',  hour: '2-digit', minute: '2-digit' }
    return new Date(dateString).toLocaleDateString('fr-FR', options)
  }

  return (
    <>
      <div className="d-grid gap-2 d-md-block">
        <button className="btn btn-outline-primary m-3" type="button" onClick={getDons}>rafraîchir la liste</button>
        <CSVLink {...csvReport} enclosingCharacter={`'`} className="btn btn-outline-success">Exporter vers csv</CSVLink>
      </div>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Date de don</th>
            <th>Donateur</th>
            <th>Email</th>
            <th>Tél</th>
            <th>Type</th>
            <th>Nom campagne</th>
            <th>Montant</th>
            <th>Date de prélèvement</th>
          </tr>
        </thead>
        <tbody>
          {don.map((doc, index) => {
            return (
              <tr key={index}>
                <td>{formatDate(doc.date_order.toDate().toISOString())}</td>
                <td>{doc.donateur}</td>
                <td>{doc.emailDinateur}</td>
                <td>{doc.telDonateur}</td>
                <td>{doc.type}</td>
                <td>{doc.id_campagne}</td>
                <td>{doc.montant}</td>
                <td>{formatDate(doc.datePrel.toDate().toISOString())}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default DonsList;